import { PoseNet, Pose as PoseNetEstimation, Keypoint as PoseNetKeypoint, load } from '@tensorflow-models/posenet';
import { Estimator, Estimation, Keypoint } from '../../store/estimator/types';
import { ModelConfig } from '@tensorflow-models/posenet/dist/posenet_model';

class PoseNetEstimator implements Estimator {
    private poseNet: PoseNet;

    constructor(poseNet: PoseNet) {
        this.poseNet = poseNet;
    }

    mapPoseNetEstimation(poseNetEstimation: PoseNetEstimation): Estimation {
        return {
            score: poseNetEstimation.score,
            keypoints: this.mapPoseNetKeypoints(poseNetEstimation.keypoints),
        }
    }

    mapPoseNetKeypoints(poseNetKeypoints: PoseNetKeypoint[]): Keypoint[] {
        let keypoints: Keypoint[] = [];
        poseNetKeypoints.forEach(poseNetKeypoint => {
            const keypoint: Keypoint = {
                score: poseNetKeypoint.score,
                name: poseNetKeypoint.part,
                position: {
                    x: poseNetKeypoint.position.x,
                    y: poseNetKeypoint.position.y
                }
            }
            keypoints.push(keypoint);
        });

        return keypoints;
    }

    estimate(input: HTMLVideoElement): Promise<Estimation> {
        return this.poseNet.estimateMultiplePoses(input)
            .then(poseNetEstimation => {
                return this.mapPoseNetEstimation(poseNetEstimation[0])
            });
    }
}

export const usePoseNetEstimator = (config: ModelConfig) => {
    return load(config).then(poseNet => {
        return new PoseNetEstimator(poseNet);
    })
}

export default PoseNetEstimator;