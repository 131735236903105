import React from "react";
import WebCam from "../components/WebCam"
import { useDispatch } from "react-redux"
import { setWebCamElement } from '../store/webcam/actions';

const WebCamContainer = () => {
    const dispatch = useDispatch();

    const handleReady = (webCamElement: HTMLVideoElement) => {
        dispatch(setWebCamElement(webCamElement));
    }

    return (
        <WebCam onReady={handleReady}/>
    )
}

export default WebCamContainer;