import { Exercise, Coach, EvaluationSchema, CoachActionTypes, Sensor } from "./types";
import { evaluationSchemaSuccess, newSensor, cleanSensors, evaluationSuccess, evaluationFailure, EvaluationSchemaSuccessAction, resetExerciseIndex, EvaluationSuccessAction, setEvents, nextExercise, cleanEvents } from "./actions";
import { EstimatorActionTypes } from "../estimator/types";
import { takeEvery, all, fork, put, select, call } from "redux-saga/effects";
import { EstimationSuccessAction } from "../estimator/actions";
import { ApplicationState } from "..";
import { endTest } from "../test/actions";

function initCoach(exercise: Exercise, coach: Coach) {
    return coach.initCoach(exercise);
}

function evaluate(sensors: Sensor[], pastEvents: Event[], coach: Coach) {
    return coach.evaluate(sensors, pastEvents);
}

function* evaluation() {
    try {
        const coach = yield select((state: ApplicationState) => state.coach.coach);
        const sensors = yield select((state: ApplicationState) => state.coach.sensors);
        const events = yield select((state: ApplicationState) => state.coach.events);
        const evaluation = yield call(evaluate, sensors, events, coach)
        yield put(evaluationSuccess(evaluation));
    } catch(err) {
        yield put(evaluationFailure(err))
    }
}

function* createEstimationSensors(action: EstimationSuccessAction) {
    for (let i = 0; i < action.estimation.keypoints.length; i++) {
        yield put(newSensor({
            name: 'keypoint-' + action.estimation.keypoints[i].name,
            value: action.estimation.keypoints[i]
        }))
    }
}

function* handleGetEvaluationSchema() {
    let exercise: Exercise = {
        rules: [
            {
                name: 'handsUp',
                event: {
                    type: 'EXERCISE_STARTED'
                },
                conditions: {
                    all: [
                        {
                            fact: 'keypoint-leftEar',
                            path: '$.position.y',
                            value: {
                                fact: 'keypoint-leftElbow',
                                path: '$.position.y'
                            },
                            operator: 'greaterThan',
                            priority: 1
                        },
                        {
                            fact: 'keypoint-rightEar',
                            path: '$.position.y',
                            value: {
                                fact: 'keypoint-rightElbow',
                                path: '$.position.y'
                            },
                            operator: 'greaterThan',
                            priority: 1
                        }
                    ]
                },
                priority: 1
            }, 
            {
                name: 'handsUpEnd',
                event: {
                    type: 'EXERCISE_ENDED'
                },
                conditions: {
                    all: [
                        {
                            fact: 'events',
                            value: 'EXERCISE_STARTED',
                            operator: 'contains'
                        },
                        {
                            fact: 'keypoint-leftEar',
                            path: '$.position.y',
                            value: {
                                fact: 'keypoint-leftElbow',
                                path: '$.position.y'
                            },
                            operator: 'lessThan',
                            priority: 1
                        },
                        {
                            fact: 'keypoint-rightEar',
                            path: '$.position.y',
                            value: {
                                fact: 'keypoint-rightElbow',
                                path: '$.position.y'
                            },
                            operator: 'lessThan',
                            priority: 1
                        }
                    ]
                },
                priority: 1
            }
        ]
    }
    let evaluationSchema: EvaluationSchema = {
        exercises: [exercise, exercise]
    }
    yield put(evaluationSchemaSuccess(evaluationSchema))
}

function* handleEvaluationSchemaSuccess(action: EvaluationSchemaSuccessAction) {
    const coach = yield select((state: ApplicationState) => state.coach.coach);
    yield put(resetExerciseIndex());
    yield call(initCoach, action.evaluationSchema.exercises[0], coach);
}

function* handleEvaluationSuccess(action: EvaluationSuccessAction) {
    yield put(cleanSensors());
    if (action.evaluation.events.find(event => event.type === "EXERCISE_ENDED")) {
        yield put(cleanEvents());
        console.log('hello');
        const currentExerciseIndex = yield select((state: ApplicationState) => state.coach.currentExerciseIndex);
        const evaluationSchema: EvaluationSchema = yield select((state: ApplicationState) => state.coach.evaluationSchema);
        if (currentExerciseIndex === (evaluationSchema.exercises.length - 1)) {
            yield put(endTest());
        } else {
            yield put(nextExercise());
        }
    } else {
        yield put(setEvents(action.evaluation.events));
    }
}

function* watchEvaluate() {
    yield takeEvery(CoachActionTypes.EVALUATE, evaluation);
}

function* watchEvaluationSuccess() {
    yield takeEvery(CoachActionTypes.EVALUATION_SUCCESS, handleEvaluationSuccess);
}

function* watchEstimationSuccess() {
    yield takeEvery(EstimatorActionTypes.ESTIMATION_SUCCESS, createEstimationSensors);
}

function* watchGetEvaluationSchema() {
    yield takeEvery(CoachActionTypes.GET_EVALUATION_SCHEMA, handleGetEvaluationSchema)
}

function* watchGetEvaluationSchemaSuccess() {
    yield takeEvery(CoachActionTypes.GET_EVALUATION_SCHEMA_SUCCESS, handleEvaluationSchemaSuccess)
}

function* coachSaga() {
    yield all([
        fork(watchEvaluate),
        fork(watchEvaluationSuccess),
        fork(watchGetEvaluationSchema),
        fork(watchEstimationSuccess),
        fork(watchGetEvaluationSchemaSuccess)
    ])
}

export default coachSaga;