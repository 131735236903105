import { TestState, TestActionTypes, TestResult } from "./types";
import { Reducer } from "redux";
import { calculateElapsedTime } from "../../utils/time";

const initialState: TestState = {
    running: false,
    testResult: {
        testStart: new Date(),
        frames: [],
        elapsedSeconds: 0
    },
    postingTestResult: false,
    postFailure: ''
}

const testResultReducer: Reducer<TestResult> = (state = initialState.testResult, action) => {
    switch (action.type) {
        case TestActionTypes.END_TEST: {
            return {...state, elapsedSeconds: calculateElapsedTime(state.testStart, new Date())}
        }
        case TestActionTypes.LOG_FRAME: {
            return {...state, frames: [...state.frames, action.frame]}
        }
        default: {
            return state
        }
    }
}

const reducer: Reducer<TestState> = (state = initialState, action) => {
    switch (action.type) {
        case TestActionTypes.START_TEST: {
            return {...state, running: true, testResult: {
                testStart: new Date(),
                frames: [],
                elapsedSeconds: 0
            }}
        }
        case TestActionTypes.END_TEST: {
            return {...state, running: false, testResult: testResultReducer(state.testResult, action)}
        }
        case TestActionTypes.LOG_FRAME: {
            return {...state, testResult: testResultReducer(state.testResult, action)}
        }
        case TestActionTypes.SEND_TEST_RESULT: {
            return {...state, postingTestResult: true}
        }
        case TestActionTypes.SEND_TEST_RESULT_SUCCESS: {
            return {...state, postingTestResult: false}
        }
        case TestActionTypes.SEND_TEST_RESULT_FAILURE: {
            return {...state, postingTestResult: false, postFailure: action.err}
        }
        default: {
            return state;
        }
    }
}

export {initialState as testInitialState}
export {reducer as testReducer}