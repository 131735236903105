import { TestActionTypes, Frame } from "./types";
import { Action } from 'redux';

export interface StartTestAction extends Action {}
export interface EndTestAction extends Action {}

export interface LogFrameAction extends Action {
    frame: Frame;
}

export interface SendTestResultAction extends Action {}
export interface TestResultSuccessAction extends Action {}
export interface TestResultFailureAction extends Action {
    err: string;
};

export interface MeasurementWindowOpenAction extends Action {}
export interface MeasurementWindowClosedAction extends Action {}

export const startTest = (): StartTestAction => {return {type: TestActionTypes.START_TEST}}
export const endTest = (): EndTestAction => {return {type: TestActionTypes.END_TEST}}

export const logFrame = (frame: Frame): LogFrameAction => {return {type: TestActionTypes.LOG_FRAME, frame}}

export const sendTestResult = (): SendTestResultAction => {return {type: TestActionTypes.SEND_TEST_RESULT}}
export const testResultSuccess = (): TestResultSuccessAction => {return {type: TestActionTypes.SEND_TEST_RESULT_SUCCESS}}
export const testResultFailure = (err: string): TestResultFailureAction => {return {type: TestActionTypes.SEND_TEST_RESULT_FAILURE, err}}

export const measurementWindowOpen = (): MeasurementWindowOpenAction => {return {type: TestActionTypes.MEASUREMENT_WINDOW_OPEN}}
export const measurementWindowClosed = (): MeasurementWindowClosedAction => {return {type: TestActionTypes.MEASUREMENT_WINDOW_CLOSED}}