import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../store';
import { startTest, endTest } from '../store/test/actions';

export const useTestRunning = () => {
    return useSelector((state: ApplicationState) => state.test.running);
}

const TestButtonContainer = () => {
    const testRunning = useTestRunning();
    const dispatch = useDispatch();

    const setTestButtonInnerText = () => {
        if (testRunning) {
            return 'End Test'
        } else {
            return 'Start Test'
        }
    }

    const setTestButtonOnClick = () => {
        if (testRunning) {
            return () => {dispatch(endTest())}
        } else {
            return () => {dispatch(startTest())}
        }
    }

    return (
        <button className="btn btn-lg btn-primary" onClick={setTestButtonOnClick()}>{setTestButtonInnerText()}</button>
    )
}

export default TestButtonContainer;