import { CoachActionTypes, Evaluation, EvaluationSchema, Coach, Sensor, Event } from './types';
import { Action } from 'redux';

export interface SetCoachAction extends Action {
    coach: Coach
}

export interface NewSensorAction extends Action {
    sensor: Sensor;
}
export interface CleanSensorsAction extends Action {}

export interface EvaluateAction extends Action {}
export interface EvaluationSuccessAction extends Action {
    evaluation: Evaluation
}
export interface EvaluationFailureAction extends Action {
    err: string
}

export interface GetEvaluationSchemaAction extends Action {}
export interface EvaluationSchemaSuccessAction extends Action {
    evaluationSchema: EvaluationSchema
}
export interface EvaluationSchemaFailureAction extends Action {
    err: string
}

export interface SetEventsAction extends Action {
    events: Event[];
}

export interface CleanEventsAction extends Action {}

export interface NextExerciseAction extends Action {}

export interface ResetExerciseIndexAction extends Action {}

export const setCoach = (coach: Coach): SetCoachAction => {return {type: CoachActionTypes.SET_COACH, coach}}

export const newSensor = (sensor: Sensor): NewSensorAction => {return {type: CoachActionTypes.NEW_SENSOR, sensor}}
export const cleanSensors = (): CleanSensorsAction => {return {type: CoachActionTypes.CLEAN_SENSORS}}

export const evaluate = (): EvaluateAction => {return {type: CoachActionTypes.EVALUATE}}
export const evaluationSuccess = (evaluation: Evaluation): EvaluationSuccessAction => {return {type: CoachActionTypes.EVALUATION_SUCCESS, evaluation}}
export const evaluationFailure = (err: string): EvaluationFailureAction => {return {type: CoachActionTypes.EVALUATION_FAILURE, err}}

export const getEvaluationSchema = (): GetEvaluationSchemaAction => {return {type: CoachActionTypes.GET_EVALUATION_SCHEMA}}
export const evaluationSchemaSuccess = (evaluationSchema: EvaluationSchema): EvaluationSchemaSuccessAction => {return {type: CoachActionTypes.GET_EVALUATION_SCHEMA_SUCCESS, evaluationSchema}}
export const evaluationSchemaFailure = (err: string): EvaluationSchemaFailureAction => {return {type: CoachActionTypes.GET_EVALUATION_SCHEMA_FAILURE, err}}

export const setEvents = (events: Event[]): SetEventsAction => {return {type: CoachActionTypes.SET_EVENTS, events: events}}
export const cleanEvents = () => {return {type: CoachActionTypes.CLEAN_EVENTS}}

export const nextExercise = (): NextExerciseAction => {return {type: CoachActionTypes.NEXT_EXERCISE}}
export const resetExerciseIndex = (): ResetExerciseIndexAction => {return {type: CoachActionTypes.RESET_EXERCISE_INDEX}}