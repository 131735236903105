import React from "react";
import WebCam from '../containers/WebCamContainer';
import TestButton from '../containers/TestButtonContainer';
import EvaluationContainer from "../containers/EvaluationContainer";
import EstimationVisual from "./EstimationVisual";

const App = () => {
    return (
        <div className='container-fluid'>
            <h1 className='row'>iDBPS</h1>
            <div className='row'>
                <div className='col-sm'><WebCam /></div>
                <div className='col-sm'><EstimationVisual /></div>
                <div className='col-sm'><EvaluationContainer /></div>
            </div>
            <div>
                <TestButton />
            </div>
        </div>
    )
}

export default App;