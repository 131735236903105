import { combineReducers, Action } from "redux";
import { fork, all, takeEvery } from "redux-saga/effects";

import { EstimatorState } from "./estimator/types";
import { WebCamState } from "./webcam/types";
import { TestState } from "./test/types";

import { testInitialState, testReducer } from "./test/reducer";
import { estimatorInitialState, estimatorReducer } from "./estimator/reducer";
import { webCamInitialState, webCamReducer } from "./webcam/reducer";
import testSaga from "./test/sagas";
import { CoachState } from "./coach/types";
import { coachInitialState, coachReducer } from "./coach/reducer";
import coachSaga from "./coach/sagas";

export interface ApplicationState {
    test: TestState;
    estimator: EstimatorState;
    webCam: WebCamState;
    coach: CoachState;
}

export const initialState: ApplicationState = {
    test: testInitialState,
    estimator: estimatorInitialState,
    webCam: webCamInitialState,
    coach: coachInitialState
}

export const rootReducer = combineReducers({
    test: testReducer,
    estimator: estimatorReducer,
    webCam: webCamReducer,
    coach: coachReducer
})

function logAction(action: Action) {
    console.log(action);
}

function* watchAllActions() {
    yield takeEvery('*', logAction)
}

export function* rootSaga() {
    yield all([
        fork(testSaga),
        fork(coachSaga),
        fork(watchAllActions)
    ])
}