import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';

import './styles/custom.scss'

import { initialState, rootReducer, rootSaga } from './store';
import App from './components/App';
import { setEstimator } from './store/estimator/actions';
import { usePoseNetEstimator } from './libs/estimator/posenet.estimator.impl';
import { useJRECoach } from './libs/coach/jre.coach.impl';
import { setCoach } from './store/coach/actions';

(async () => {
    const sagaMiddleware = createSagaMiddleware();

    const store = createStore(
        rootReducer,
        initialState,
        applyMiddleware(sagaMiddleware)
    );

    sagaMiddleware.run(rootSaga)

    const poseNetEstimator = await usePoseNetEstimator({
        architecture: 'MobileNetV1',
        inputResolution: 257,
        outputStride: 16,
        multiplier: 0.75,
        quantBytes: 2
    });
    store.dispatch(setEstimator(poseNetEstimator));

    const jRECoach = useJRECoach();
    store.dispatch(setCoach(jRECoach));

    ReactDOM.render((
        <Provider store={store}>
            <App />
        </Provider>
    ), document.getElementById('root'));
})()
