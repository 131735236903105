import { all, fork, put, select, call, take, cancel, delay, takeEvery } from 'redux-saga/effects';
import { TestActionTypes, TestResult } from './types';
import { testResultSuccess, testResultFailure, measurementWindowOpen, measurementWindowClosed, logFrame, sendTestResult } from './actions';
import { ApplicationState } from '..';
import estimation from '../estimator/sagas';
import { evaluate, getEvaluationSchema } from '../coach/actions';

function postTestResult(url: string, data: TestResult) {
    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(res => res.json())
        .catch(err => err)
}

function* handleSendTestResult() {
    try {
        const testResult = yield select((state: ApplicationState) => state.test.testResult);
        console.log("Send")
        yield call(postTestResult, 'api/test', testResult);
        yield put(testResultSuccess());
    } catch (err) {
        yield put(testResultFailure(err));
    }
}

function* afterMeasurementWindow() {
    const latestEstimation = yield select((state: ApplicationState) => state.estimator.latestEstimation)
    yield put(logFrame({
        frameDate: new Date(),
        exerciseId: '',
        ...latestEstimation
    }));
    yield put(evaluate());
}

function* measurementWindow(framesPerSecond: number) {
    yield put(measurementWindowOpen());
    yield delay(1000 / framesPerSecond);
    yield put(measurementWindowClosed());
}

function* testLoop() {
    while (true) {
        yield fork(measurementWindow, 10);
        const estimationFork = yield fork(estimation);

        yield take(TestActionTypes.MEASUREMENT_WINDOW_CLOSED);
        yield cancel(estimationFork);

        yield call(afterMeasurementWindow);
    }
}

function* main() {
    while (yield take(TestActionTypes.START_TEST)) {
        yield put(getEvaluationSchema())
        const testLoopFork = yield fork(testLoop)

        yield take(TestActionTypes.END_TEST);
        yield cancel(testLoopFork);
        yield put(sendTestResult())
    }
}

function* watchSendTestResult() {
    yield takeEvery(TestActionTypes.SEND_TEST_RESULT, handleSendTestResult)
}

function* testSaga() {
    yield all([
        fork(main),
        fork(watchSendTestResult)
    ])
}

export default testSaga;