import { WebCamState, WebCamActionTypes } from "./types";
import { Reducer } from "redux";

const initialState: WebCamState = {
    webCamElement: document.createElement('video')
}

const reducer: Reducer<WebCamState> = (state = initialState, action) => {
    switch (action.type) {
        case WebCamActionTypes.SET_WEBCAM_ELEMENT: {
            return {...state, webCamElement: action.webCamElement}
        }
        default: {
            return state;
        }
    }
}

export {initialState as webCamInitialState}
export {reducer as webCamReducer}