import { Estimator } from "./types";
import { put, select, call } from "redux-saga/effects";
import { estimationFailure, estimationSuccess } from "./actions";
import { ApplicationState } from "..";

function estimate(input: HTMLVideoElement, estimator: Estimator) {
    return estimator.estimate(input);
}

function* estimation() {
    try {
        const estimator = yield select((state: ApplicationState) => state.estimator.estimator)
        const input = yield select((state: ApplicationState) => state.webCam.webCamElement)
        const estimation = yield call(estimate, input, estimator);
        yield put(estimationSuccess(estimation));
    } catch {
        yield put(estimationFailure('estimation failed.'));
    }
}

export default estimation;