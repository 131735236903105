export interface Position {
    x: number,
    y: number
}

export interface Keypoint {
    score: number,
    name: string,
    position: Position
}

export interface Estimation {
    score: number,
    keypoints: Keypoint[]
}
export interface Estimator {
    estimate: (input: HTMLVideoElement) => Promise<Estimation>
}

export enum EstimatorActionTypes {
    SET_ESTIMATOR = '@@estimator/SET_ESTIMATOR',
    START_ESTIMATOR = '@@estimator/START_ESTIMATOR',
    STOP_ESTIMATOR = '@@estimator/STOP_ESTIMATOR',
    ESTIMATION_START = '@@estimator/ESTIMATION_START',
    ESTIMATION_SUCCESS = '@@estimator/ESTIMATION_SUCCESS',
    ESTIMATION_FAILURE = '@@estimator/ESTIMATION_FAILURE'
}

export interface EstimatorState {
    readonly estimator: Estimator,
    readonly running: boolean,
    readonly estimating: boolean
    readonly latestEstimation: Estimation,
    readonly latestFailure: string
}