import { Estimation } from "../estimator/types";

export interface Frame extends Estimation {
    frameDate: Date;
    exerciseId: string;
}

export interface TestResult {
    testStart: Date;
    frames: Frame[];
    elapsedSeconds: number
}

export enum TestActionTypes {
    START_TEST = '@@test/START_TEST',
    END_TEST = '@@test/END_TEST',
    LOG_FRAME = '@@test/LOG_FRAME',
    SEND_TEST_RESULT = '@@test/SEND_TEST_RESULT',
    SEND_TEST_RESULT_SUCCESS = '@@test/SEND_TEST_RESULT_SUCCESS',
    SEND_TEST_RESULT_FAILURE = '@@test/SEND_TEST_RESULT_FAILURE',
    MEASUREMENT_WINDOW_OPEN = '@@test/MEASUREMENT_WINDOW_OPEN',
    MEASUREMENT_WINDOW_CLOSED = '@@test/MEASUREMENT_WINDOW_CLOSED'
}

export interface TestState {
    readonly running: boolean;
    readonly testResult: TestResult;
    readonly postingTestResult: boolean;
    readonly postFailure: string;
}