import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../store';

export const useEvaluation = () => {
    return useSelector((state: ApplicationState) => state.coach.latestEvaluation);
}

export const useEvaluationSchema = () => {
    return useSelector((state: ApplicationState) => state.coach.evaluationSchema);
}

export const useCurrentExerciseIndex = () => {
    return useSelector((state: ApplicationState) => state.coach.currentExerciseIndex);
}

const EvaluationContainer = () => {
    const evaluation = useEvaluation();
    const evaluationSchema = useEvaluationSchema();
    const currentExerciseIndex = useCurrentExerciseIndex();

    const createResult = () => {
        let events: JSX.Element[] = [];
        evaluation.events.forEach(event => {
            events.push(<p>{event.type}</p>)
        })
        return events;
    }

    const createOverview = () => {
        if (evaluationSchema.exercises.length > 0) {
            return (
                <>
                    <p>Current Exercise: {evaluationSchema.exercises[currentExerciseIndex].rules[0].name}</p>
                    <p>Exercise: {currentExerciseIndex + 1} out of {evaluationSchema.exercises.length}</p>
                </>
            )
        } else {
            return (
                <></>
            )
        }
    }

    return (
        <>
            {createOverview()}
            <div className="page-header"></div>
            <p>Events: {createResult()}</p>
        </>
    )
}

export default EvaluationContainer;