import { EstimatorState, EstimatorActionTypes } from "./types";
import { Reducer } from "redux";

const initialState: EstimatorState = {
    estimator: {
        estimate: (input: HTMLVideoElement) => Promise.reject()
    },
    running: false,
    estimating: false,
    latestEstimation: {
        score: 0,
        keypoints: []
    },
    latestFailure: ''
}

const reducer: Reducer<EstimatorState> = (state = initialState, action) => {
    switch (action.type) {
        case EstimatorActionTypes.SET_ESTIMATOR: {
            return {...state, estimator: action.estimator}
        }
        case EstimatorActionTypes.START_ESTIMATOR: {
            return {...state, running: true}
        }
        case EstimatorActionTypes.STOP_ESTIMATOR: {
            return {...state, running: false}
        }
        case EstimatorActionTypes.ESTIMATION_START: {
            return {...state, estimating: true}
        }
        case EstimatorActionTypes.ESTIMATION_SUCCESS: {
            return {...state, latestEstimation: action.estimation, estimating: false}
        }
        case EstimatorActionTypes.ESTIMATION_FAILURE: {
            return {...state, latestFailure: action.err, estimating: false}
        }
        default: {
            return state;
        }
    }
}

export {initialState as estimatorInitialState}
export {reducer as estimatorReducer}