/* eslint-disable max-params */
import * as posenet from '@tensorflow-models/posenet';

const pointRadius = 3;

export const config = {
  videoWidth: 900,
  videoHeight: 700,
  flipHorizontal: true,
  algorithm: 'single-pose',
  showVideo: true,
  showSkeleton: true,
  showPoints: true,
  minPoseConfidence: 0.1,
  minPartConfidence: 0.5,
  maxPoseDetections: 2,
  nmsRadius: 20,
  outputStride: 16,
  imageScaleFactor: 0.5,
  skeletonColor: 'black',
  skeletonLineWidth: 6,
  loadingText: 'Loading...please be patient...'
};

const toTuple = ({ x, y }) => [x, y];

export const drawKeyPoints = (
  keypoints,
  minConfidence,
  skeletonColor,
  canvasContext,
  scale = 1
) => {
  keypoints.forEach((keypoint) => {
    if (keypoint.score >= minConfidence) {
      const { x, y } = keypoint.position;
      canvasContext.beginPath();
      canvasContext.arc(x * scale, y * scale, pointRadius, 0, 2 * Math.PI);
      canvasContext.fillStyle = skeletonColor;
      canvasContext.fill();
    }
  });
};

const drawSegment = (
  [firstX, firstY],
  [nextX, nextY],
  color,
  lineWidth,
  scale,
  canvasContext
) => {
  canvasContext.beginPath();
  canvasContext.moveTo(firstX * scale, firstY * scale);
  canvasContext.lineTo(nextX * scale, nextY * scale);
  canvasContext.lineWidth = lineWidth;
  canvasContext.strokeStyle = color;
  canvasContext.stroke();
};

export const drawSkeleton = (
  keypoints,
  minConfidence,
  color,
  lineWidth,
  canvasContext,
  scale = 1
) => {
  const adjacentKeyPoints = posenet.getAdjacentKeyPoints(
    keypoints,
    minConfidence
  );

  adjacentKeyPoints.forEach((_keypoints) => {
    drawSegment(
      toTuple(_keypoints[0].position),
      toTuple(_keypoints[1].position),
      color,
      lineWidth,
      scale,
      canvasContext
    );
  });
};
