import { Estimator, EstimatorActionTypes, Estimation } from "./types";
import { Action } from "redux";

export interface EstimationSuccessAction extends Action {
    estimation: Estimation
}

export const setEstimator = (estimator: Estimator) => {return {type: EstimatorActionTypes.SET_ESTIMATOR, estimator}}

export const startEstimator = () => {return {type: EstimatorActionTypes.START_ESTIMATOR}}
export const stopEstimator = () => {return {type: EstimatorActionTypes.STOP_ESTIMATOR}}

export const estimationStart = () => {return {type: EstimatorActionTypes.ESTIMATION_START}}
export const estimationSuccess = (estimation: Estimation): EstimationSuccessAction => {return {type: EstimatorActionTypes.ESTIMATION_SUCCESS, estimation}}
export const estimationFailure = (err: string) => {return {type: EstimatorActionTypes.ESTIMATION_FAILURE, err}}