export interface Event {
    type: string;
    params?: Record<string, any>;
  }

interface ConditionProperties {
    fact: string;
    operator: string;
    value: { fact: string } | any;
    path?: string;
    priority?: number;
    params?: Record<string, any>;
}

type NestedCondition = ConditionProperties | TopLevelCondition;
type AllConditions = { all: NestedCondition[] };
type AnyConditions = { any: NestedCondition[] };
export type TopLevelCondition = AllConditions | AnyConditions;

export interface Rule {
    name: string;
    conditions: TopLevelCondition;
    event: Event;
    priority: number;
}

export interface Exercise {
    rules: Rule[]
}

export interface EvaluationSchema {
    exercises: Exercise[]
}

export interface Sensor<T = unknown> {
    name: string;
    value: T;
}

export interface Evaluation {
    events: Event[];
}

export interface Coach {
    initCoach(exercise: Exercise): void;
    evaluate(sensors: Sensor[], pastEvents: Event[]): Promise<Evaluation>;
}

export enum CoachActionTypes {
    SET_COACH = '@@coach/SET_COACH',
    NEW_SENSOR = '@@coach/NEW_SENSOR',
    CLEAN_SENSORS = '@@coach/CLEAN_SENSORS',
    EVALUATE = "@@coach/EVALUATE",
    EVALUATION_SUCCESS = "@@coach/EVALUATE_SUCCESS",
    EVALUATION_FAILURE = "@@coach/EVALUATE_FAILURE",
    GET_EVALUATION_SCHEMA = "@@coach/GET_EVALUATION_SCHEMA",
    GET_EVALUATION_SCHEMA_SUCCESS = "@@coach/GET_EVALUATION_SCHEMA_SUCCESS",
    GET_EVALUATION_SCHEMA_FAILURE = "@@coach/GET_EVALUATION_SCHEMA_FAILURE",
    NEXT_EXERCISE = "@@coach/NEXT_EXERCISE",
    SET_EVENTS = "@@coach/SET_EVENTS",
    CLEAN_EVENTS = "@@coach/CLEAN_EVENTS",
    RESET_EXERCISE_INDEX = "@@coach/RESET_EXERCISE_INDEX"
}

export interface CoachState {
    readonly coach: Coach;
    readonly sensors: Sensor[];
    readonly events: Event[];
    readonly currentExerciseIndex: number;
    readonly evaluating: boolean;
    readonly latestEvaluation: Evaluation;
    readonly latestEvaluationFailure: string;
    readonly evaluationSchema: EvaluationSchema;
    readonly gettingEvaluationSchema: boolean;
    readonly latestEvaluationSchemaFailure: string;
}