import { CoachState, CoachActionTypes, Exercise, Sensor } from './types';
import { Reducer } from 'redux';

const initialState: CoachState = {
    coach: {
        initCoach: (exercise: Exercise) => {},
        evaluate: (sensors: Sensor[], pastEvents: Event[]) => Promise.reject()
    },
    sensors: [],
    evaluating: false,
    latestEvaluation: {
        events: []
    },
    evaluationSchema: {
        exercises: []
    },
    gettingEvaluationSchema: false,
    latestEvaluationFailure: "",
    latestEvaluationSchemaFailure: "",
    events: [],
    currentExerciseIndex: 0
}

const reducer: Reducer<CoachState> = (state = initialState, action) => {
    switch (action.type) {
        case CoachActionTypes.SET_COACH: {
            return {...state, coach: action.coach}
        }
        case CoachActionTypes.NEW_SENSOR: {
            return {...state, sensors: [...state.sensors, action.sensor]}
        }
        case CoachActionTypes.CLEAN_SENSORS: {
            return {...state, sensors: []}
        }
        case CoachActionTypes.EVALUATE: {
            return {...state, evaluating: true}
        }
        case CoachActionTypes.EVALUATION_SUCCESS: {
            return {...state, latestEvaluation: action.evaluation}
        }
        case CoachActionTypes.EVALUATION_FAILURE: {
            return {...state, latestEvaluationFailure: action.err}
        }
        case CoachActionTypes.GET_EVALUATION_SCHEMA: {
            return {...state, gettingEvaluationSchema: true}
        }
        case CoachActionTypes.GET_EVALUATION_SCHEMA_SUCCESS: {
            return {...state, evaluationSchema: action.evaluationSchema}
        }
        case CoachActionTypes.GET_EVALUATION_SCHEMA_FAILURE: {
            return {...state, latestEvaluationSchemaFailure: action.err}
        }
        case CoachActionTypes.SET_EVENTS: {
            return {...state, events: [...state.events, ...action.events]}
        }
        case CoachActionTypes.CLEAN_EVENTS: {
            return {...state, events: []}
        }
        case CoachActionTypes.NEXT_EXERCISE: {
            return {...state, currentExerciseIndex: state.currentExerciseIndex + 1}
        }
        case CoachActionTypes.RESET_EXERCISE_INDEX: {
            return {...state, currentExerciseIndex: 0}
        }
        default: {
            return state;
        }
    }
}

export {initialState as coachInitialState}
export {reducer as coachReducer}