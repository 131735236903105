import React, {FunctionComponent, useEffect } from 'react';

type WebCamProps = {
    onReady: (webCamElement: HTMLVideoElement) => void
}

const WebCam: FunctionComponent<WebCamProps> = (props) => {
    const webCamRef = React.createRef<HTMLVideoElement>();

    useEffect(() => {
        const node = webCamRef.current!;
        node.focus();

        navigator.mediaDevices.getUserMedia({
            audio: false,
            video: true
        }).then(stream => {
            node.srcObject = stream;
            node.play();
            node.onloadeddata = () => {props.onReady(node)};
        })
    })

    return (
        <video width="600" height="500" ref={webCamRef}></video>
    )
}

export default WebCam;