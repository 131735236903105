import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../store';
import { drawKeyPoints, drawSkeleton } from './utils';

const useEstimation = () => {
    return useSelector((state: ApplicationState) => state.estimator.latestEstimation);
}

const defaultProps = {
    videoWidth: 600,
    videoHeight: 500,
    flipHorizontal: false,
    algorithm: 'multi-pose',
    showVideo: false,
    showSkeleton: true,
    showPoints: true,
    minPoseConfidence: 0.1,
    minPartConfidence: 0.5,
    maxPoseDetections: 2,
    nmsRadius: 20,
    outputStride: 16,
    imageScaleFactor: 0.5,
    skeletonColor: 'black',
    skeletonLineWidth: 6,
    loadingText: 'Loading...please be patient...'
  };

const EstimationVisual = () => {
    const {
        minPoseConfidence,
        minPartConfidence,
        videoWidth,
        videoHeight,
        showPoints,
        showSkeleton,
        skeletonColor,
        skeletonLineWidth
      } = defaultProps;

    const estimation = useEstimation();
    const canvasRef = React.createRef<HTMLCanvasElement>();

    useEffect(() => {
        const node = canvasRef.current!;
        node.focus();
        
        const canvasContext = node.getContext('2d');

        node.width = videoWidth;
        node.height = videoHeight;

        if (estimation.keypoints.length > 0) {
          if (estimation.score >= minPoseConfidence) {
            if (showPoints) {
              drawKeyPoints(
                estimation.keypoints,
                minPartConfidence,
                skeletonColor,
                canvasContext
              );
            }
            if (showSkeleton) {
              drawSkeleton(
                estimation.keypoints,
                minPartConfidence,
                skeletonColor,
                skeletonLineWidth,
                canvasContext
              );
            }
          }
        }
    })

    return (
        <>
            <canvas width="600" height="500" ref={canvasRef} />
        </>
    )
}

export default EstimationVisual;