import { Coach, Exercise, Sensor, Evaluation, Event } from "../../store/coach/types";
import RuleEngine, { Engine, RuleProperties, Fact, EngineResult } from "json-rules-engine";

class JsonRulesEngineCoach implements Coach {
    constructor(
        private coach: Engine = RuleEngine([])
    ) {}
    
    serializeRules(exercise: Exercise) {
        let ruleProps: RuleProperties[] = [];
        exercise.rules.forEach(rule => {
            ruleProps.push({...rule})
        });
        
        return ruleProps;
    }

    serializeFacts(sensors: Sensor[]) {
        let facts: Fact[] = [];
        sensors.forEach(sensor => {
            facts.push(new Fact(sensor.name, sensor.value));
        })

        return facts;
    }

    serializeEventsFact(events: Event[]) {
        const eventsFact: string[] = [];
        events.forEach((event: Event) => {
            eventsFact.push(event.type);
        })
        return new Fact('events', eventsFact);
    }

    mapEngineResult(engineResult: EngineResult) {
        return {events: engineResult.events} as Evaluation
    }

    initCoach(exercise: Exercise) {
        let rules = this.serializeRules(exercise);
        this.coach = RuleEngine(rules);
    }

    evaluate(sensors: Sensor[], pastEvents: Event[]): Promise<Evaluation> {
        let facts = this.serializeFacts(sensors);
        facts.push(this.serializeEventsFact(pastEvents));
        console.log(pastEvents);
        return this.coach.run(facts).then(engineResult => {
            return this.mapEngineResult(engineResult);
        });
    }
}

export const useJRECoach = () => {
    return new JsonRulesEngineCoach();
}